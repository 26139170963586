@import '../../../../media-query';
@import '../../../../styles/constants/colors.scss';
@import '../../../../styles/constants/fonts.scss';

.body__edit-email {
    margin-top: 60px;
    flex-direction: column;
    align-items: center;
    font-family: 'Frutiger';
    @include responsive(s) {
        margin-top: 10px;
    }

    .body__email-field--lowercase {
        input {
            text-transform: lowercase;
        }
    }

    .body__password--buttons {
        display: flex;
        flex-wrap: initial;
        margin-bottom: 30px;
        @include responsive(s) {
            padding-top: 10px;
            margin-bottom: 5px;
            flex-wrap: nowrap;
        }
        @include responsive(ss) {
            padding-top: 10px;
            margin-bottom: -14px;
            flex-wrap: nowrap;
        }
    }

    &--title {
        margin: auto;
        font-size: 1.375rem;
        text-align: center;
        margin-bottom: 48px;
        line-height: 1.3;
        @include responsive(s) {
            margin-bottom: 15px;
        }

        span {
            font-weight: 500;
        }
    }

    .form__style {
        margin-bottom: 50px;
        @include responsive(s) {
            margin-bottom: 10px;
        }
    }
}

.body__password--button-cancel {
    display: flex;
    justify-content: flex-end;
    @include responsive(ss) {
        justify-content: center;
        padding-bottom: 20px;
    }
}

.body__password--button-save {
    display: flex;
    justify-content: flex-start;
    @include responsive(ss) {
        justify-content: center;
    }
}
