@import '../../media-query';
@import '../../styles/constants/colors.scss';

.container__dashboard {
    padding-top: 70px;
    background-color: #fafafa;
    height: 100vh;

    .dashboard--title {
        display: flex;
        flex-direction: column;
        font-family: 'Frutiger';
        font-size: 1.25rem;
        color: $greyish_brown;
        font-weight: 300;
        margin-bottom: 5px;

        span {
            font-weight: 400;
        }
    }

    .dashboard--link {
        font-family: 'Frutiger';
        font-size: 0.813rem;
        color: $cool_blue_two;
        text-align: right;
        text-decoration: none;

        a {
            color: $cool_blue_two;
        }

        .link--arrow {
            width: 17px;
        }
    }

    .dashboard--can-not-anticipate {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 60vh;
        font-family: 'Catamaran', sans-serif;
        font-size: 1.25rem;
        color: $scorpion;
        text-align: center;
        line-height: 20px;
        white-space: pre-wrap;
    }
}
