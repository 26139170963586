@import '../../../../media-query';
@import '../../../../styles/constants/colors.scss';

.auto-login__loading {
    .gfLNMF {
        height: 18px;
        width: 18px;
        .iepmoa {
            height: 9px;
            width: 9px;
            background-color: $organization_secondary;
        }
    }
}
