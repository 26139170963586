@import '../../media-query';
@import '../../styles/constants/colors.scss';
@import '../../styles/constants/fonts.scss';

.password__strength--container {
    display: flex;
    flex-direction: row;
    width: 137px;
    @include responsive (s) {
        width: 100%;
    }

    .password__strength:nth-child(1) {
        width: 25px;
        height: 4px;
        border-radius: 4px;
        background: $piss_yellow;
        padding-right: 10px;
        margin-right: 3px;
        @include responsive(s) {
            width: 34px;
            height: 5px;
            border-radius: 6px;
            margin-right: 4px;
        }
    }

    .password__strength:nth-child(2) {
        width: 25px;
        height: 4px;
        border-radius: 4px;
        background: $very_light_pink_two;
        margin-right: 3px;

        &[data-showcolor="true"]{
            background: $piss_yellow; 
        }

        @include responsive(s) {
            width: 34px;
            height: 5px;
            border-radius: 6px;
            margin-right: 4px;
        }
    }

    .password__strength:nth-child(3) {
        width: 25px;
        height: 4px;
        border-radius: 4px;
        background: $very_light_pink_two;
        margin-right: 3px;

        &[data-showcolor="true"]{
            background: $gross_green; 
        }

        @include responsive(s) {
            width: 34px;
            height: 5px;
            border-radius: 6px;
            margin-right: 4px;
        }
    }

    .password__strength:nth-child(4) {
        width: 25px;
        height: 4px;
        border-radius: 4px;
        background: $very_light_pink_two;
        margin-right: 3px;

        &[data-showcolor="true"]{
            background: $soft_green; 
        }

        @include responsive(s) {
            width: 34px;
            height: 5px;
            border-radius: 6px;
            margin-right: 4px;
        }
    }

    .password__strength:nth-child(5) {
        width: 25px;
        height: 4px;
        border-radius: 4px;
        background: $very_light_pink_two;

        &[data-showcolor="true"]{
            background: $soft_green; 
        }

        @include responsive(s) {
            width: 34px;
            height: 5px;
            border-radius: 6px;
        }
    }
}
