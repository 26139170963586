@import '../../../../media-query';
@import '../../../../styles/constants/colors.scss';
.program-chart {
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: center;
    .program-chart__chart-bar {
        width: 4px;
        background-color: $organization_program_default;
        height: 30px;
        margin-right: 2px;
        &:nth-child(1),
        &:nth-child(4),
        &:nth-child(6),
        &:nth-child(9) {
            height: 18px;
        }
        &:nth-child(2),
        &:nth-child(7) {
            height: 23px;
        }
        &:nth-child(1) {
            border-top-left-radius: 3px;
        }
    }
}