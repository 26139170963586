@import '../../media-query';
@import '../../styles/constants/colors.scss';

.password__style--field {
    .password__adornment {
        .MuiSvgIcon-root {
            color: $brown_grey_two;
        }
    }
}
