@import '../../../../../media-query';
@import '../../../../../styles/constants/colors.scss';

.anticipation-term-session {
    margin: 0 5px;
    overflow-y: scroll;
    min-height: 20vh;
    max-height: 30vh;
    border: solid 1px #ccc;
    border-radius: 3px;
    padding: 10px;
    position: relative;
    z-index: 0;
    
    &__text {
        font-family: 'Frutiger';
        font-size: 0.875rem;
        font-weight: 300;
        text-align: left;
    }

    p {
        margin-right: 15px;
    }
}
