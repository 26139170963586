@import '../../../../media-query';
@import '../../../../styles/constants/colors.scss';

.company-selection-item {
    width: 100%;
    height: 80px;
    background: white;
    border: solid 1px $very_light_pink_two;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    align-items: center;

    .company-selection-item__company-identification {
        font-family: Frutiger;
        font-size: 1rem;
        display: flex;
        flex-direction: column;
        color: $greyish_brown;
        justify-content: center;
        margin-left: 15px;
        flex: 3;
        span {
            font-size: 0.813rem;
            color: $brown_grey;
        }
    }

    .company-selection-item__selection-border {
        width: 1px;
        height: 50px;
        background: $very_light_pink_two;
        margin-left: 6px;
        margin-right: 6px;
    }

    .company-selection-item__company-credits {
        font-family: Frutiger;
        font-size: 1rem;
        display: flex;
        flex-direction: column;
        color: $greyish_brown;
        justify-content: center;
        align-items: center;
        flex: 2.5;
        span {
            font-size: 0.75rem;
            color: $brown_grey;
        }
    }

    .company-selection-item__actions {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        padding-left: 12px;
        padding-right: 18px;
        .MuiButton-root {
            width: 110px;
            height: 36px;
            min-width: 110px;
            min-height: 36px;
            font-size: 0.75rem;
            border-radius: 6px;
        }
    }
}
