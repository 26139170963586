@import '../../../../../media-query.scss';
@import '../../../../../styles/constants/colors.scss';

.body__anticipation-success--itens {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .body__anticipation-success--image {
        content: url(../../../../../images/cadeado.svg);
        margin-bottom: 25px;
    }

    .body__anticipation-success--title {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-bottom: 50px;
        font-family: Frutiger;
        font-size: 1rem;
        color: $greyish_brown;
        font-weight: 300;
        letter-spacing: 1.1px;

        span {
            font-size: 3.125rem;
            font-weight: 500;
            background: $greenish_teal;
            border-radius: 6px;
            margin-top: 10px;
            margin-bottom: 15px;
        }
    }

    .body__anticipation-success--line {
        background: $very_light_pink_two;
        width: 100vh;
        height: 1px;
        margin-bottom: 35px;
    }

    .body__anticipation-success--subtitle {
        font-family: Frutiger;
        font-size: 1.625rem;
        font-weight: 300;
        margin-bottom: 20px;
    }

    .body__anticipation-success--final-text {
        font-family: Frutiger;
        font-size: 1.125rem;
        font-weight: 300;
        line-height: 26px;
        margin-bottom: 35px;
        text-align: center;
        width: 400px;
        color: $greyish_brown;

        span {
            font-weight: 400;
        }
    }

    .body__anticipation-success--button {
        border: unset;
        width: 173px;
        background: $organization_primary;
        color: white;
        border-radius: 8px;
        font-family: Frutiger;
        font-size: 0.75rem;
        height: 45px;
        letter-spacing: 1.3px;
        font-weight: 500;
    }

    .body__anticipation-success--button:hover {
        background: $organization_primary_focus;
    }
}
