@import '../../../media-query';
@import '../../../styles/constants/colors.scss';
@import '../../../styles/constants/fonts.scss';
.selectable-column__items {
    display: flex;
    align-items: center;
    &--text {
        font-family: Frutiger;
        font-size: 12px;
        color: $brown_grey;
    }
    &--icon {
        margin-left: 12px;
    }
}