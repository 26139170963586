@use "~styles/constants" as *;

.button__style--close {
    .MuiIconButton-root {
        padding: 5px;
        outline: none;
        &:hover {
            background: none;
            .button__style--close-icon {
                content: url('../../images/close-hover.svg');
            }
        }
    }
    .MuiIcon-root {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 22px;
        width: 22px;
    }
    &-icon {
        content: url('../../images/close-rest.svg');
        height: 17px;
        width: 17px;
    }
}
