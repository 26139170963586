@import '../../../../../styles/constants/colors.scss';

.organization-notification {
    &__container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        width: 19vw;
        padding: 10px 10px 10px 20px;
        margin: 5px;

        position: relative;
        &--INFO {
            background-color: $aqua_haze;
        }
        &--WARNING {
            background-color: $citrine_white;
        }
        &--PROBLEM {
            background-color: $cherub;
        }
    }

    &__title {
        font-family: Frutiger;
        font-size: 1.2 rem;
        font-weight: 500;
    }

    &__message {
        font-family: Frutiger;
        font-size: 0.8 rem;
        font-weight: 300;
    }

    &__icon {
        &--close {
            content: url('./../../../../../images/fechar-3.svg');
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
}
