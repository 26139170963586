@import '../../../../media-query.scss';
@import '../../../../styles/constants/colors.scss';

.container__filter {
    margin: 0 auto 15px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    width: 75%;

    .MuiFormControl-root {

        .MuiInputLabel-root {
            display: none;
        }

        .MuiInputBase-root {
            display: flex;
            align-items: center;
            padding: 15px 12px;
            
            .MuiInputAdornment-root {
                color : var(--gray);
                margin: 0;
            }
            
            .MuiInputBase-input {
                padding: 0 0 0 12px;
            }
        }
    } 
}