@import '../../../../media-query';
@import '../../../../styles/constants/colors.scss';

.program-selection-item {
    width: 100%;
    height: 80px;
    background: white;
    border: solid 1px $very_light_pink_two;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;

    .program-selection-item__program-details {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .program-selection-item__program-details--color {
            background-color: $organization_program_default;
            width: 5px;
            height: 78px;
        }
    }

    .program-selection-item__program-name {
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: -15px;
        font-family: Frutiger;
        font-size: 1rem;
        color: $greyish_brown;
    }

    .program-selection-item__program-buyer {
        font-family: Frutiger;
        font-size: 0.875rem;
        display: flex;
        flex-direction: column;
        color: $greyish_brown;
        justify-content: center;
        height: 100%;

        span {
            font-size: 0.813rem;
            color: $brown_grey;
        }
    }

    .program-selection-item__program-credits {
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: space-between;

        .program-selection-item__program-credits--border {
            width: 1px;
            height: 50px;
            background: $very_light_pink_two;
        }

        .program-selection-item__program-credits--value {
            font-family: Frutiger;
            font-size: 1rem;
            display: flex;
            flex-direction: column;
            color: $greyish_brown;
            justify-content: center;
            height: 100%;
            align-items: center;

            span {
                font-size: 0.75rem;
                color: $brown_grey;
            }
        }

        .program-selection-item__program-credits--image {
            content: url('../../../../images/Path-dash.svg');
            padding: 20px;
            margin-right: 33px;
            cursor: pointer;
        }
    }
}
