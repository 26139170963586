@import '../../media-query';
@import '../../styles/constants/colors.scss';
@import '../../styles/constants/fonts.scss';

.number__field {
    input.MuiInputBase-input.MuiFilledInput-input {
        box-shadow: white 500px 500px inset;
        border-radius: 5px;
    }
}
