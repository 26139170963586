@import '../../media-query';
@import '../../styles/constants/colors.scss';
@import '../../styles/constants/fonts.scss';

.container__password-reset {
    padding-top: 80px;
    padding-bottom: 50px;
    @include responsive(s) {
        min-height: 82vh;
        padding-top: 0px;
        padding-bottom: 0;
    }
    .password-reset--loading {
        .gfLNMF {
            .iepmoa {
                background-color: $organization_primary_focus;
            }
        }
    }
}
