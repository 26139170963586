@import '../../../../../media-query';
@import '../../../../../styles/constants/colors.scss';

.anticipation-request-confimation {
    background-color: $white_two;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 70px 0 100px;
    
    .body__confirmation--itens {
        font-family: 'Frutiger';
        text-align: center;
        height: 100%;

        .body__confirmation--title-start {
            font-size: 1.5rem;
            font-weight: 300;
            color: $greyish_brown;
            line-height: 28px;
            margin: 20px 0 30px;

            &-one {
                white-space: nowrap;
            }
            &-two {
                font-weight: 400;
            }
        }

        .body__confirmation--image {
            content: url('../../../../../images/positivo.svg');
            display: flex;
            justify-content: center;
        }
        
        .body__confirmation--content { 
            display: flex;
            justify-content: center;
            width: 100%
        }
        
        .body__confirmation--button {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 25px;

            .body__confirmation--button-cancel,
            .body__confirmation--button-confirm {
                border-radius: 8px;
                font-family: Frutiger;
                font-size: 0.75rem;
                height: 45px;
                letter-spacing: 1.3px;
                font-weight: 500;
            }

            .body__confirmation--button-cancel {
                margin-right: 12px;
                border: solid 1px $very_light_pink_two;
                width: 166px;
                background: white;
                color: $brown_grey_three;
            }

            .body__confirmation--button-cancel:hover {
                border-color: $brown_grey;
                color: $brown_grey;
            }

            .body__confirmation--button-confirm {
                margin-left: 12px;
                border: unset;
                width: 173px;
                background: $organization_primary;
                color: white;
            }

            .body__confirmation--button-confirm:hover {
                background: $organization_primary_focus;
            }
        }
    }
}
