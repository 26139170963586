@import '../../../../media-query';
@import '../../../../styles/constants/colors.scss';
@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@600&display=swap');

.program-card__items {
    display: flex;
    align-items: center;
    background: white;
    overflow: hidden;

    .MuiPaper-elevation1 {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    }

    &--color {
        width: 5px;
        height: 75px;
        background: #406afa;
    }

    &--text {
        padding-left: 30px;

        .program-card__items--title {
            font-family: Catamaran;
            font-size: 2.375rem;
            color: $greyish_brown;
            line-height: 1;
        }

        .program-card__items--subtitle {
            font-size: 0.688rem;
            font-family: Frutiger;
            color: $greyish_brown;
        }
    }
}
