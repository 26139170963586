@import '../../../../../media-query';
@import '../../../../../styles/constants/colors.scss';
.container__scroll {
    height: 340px;
    border-bottom: solid 1px $very_light_pink_two;
    background: $white_two;
    z-index: 10;
    overflow: hidden;
    transition: height 300ms ease;
    padding-top: 44px;
    &--small {
        height: 85px;
        .scroll--text {
            height: 260px !important;
        }
        .scroll--text-title {
            font-size: 0.75rem !important;
        }
        .scroll--text-price {
            font-size: 1.625rem !important;
            margin-bottom: 20px;
        }
    }
    .scroll--text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 293px;
    }
    .scroll--text-title {
        display: flex;
        font-family: Frutiger;
        font-size: 0.875rem;
        color: $greyish_brown;
        font-weight: 300;
    }
    .scroll--text-price {
        font-size: 2.125rem;
        font-family: Frutiger;
    }
    .scroll--text-button {
        border: none;
        background: $sick_green;
        width: 210px;
        height: 45px;
        border-radius: 10px;
        color: white;
        font-size: 0.875rem;
        font-family: Frutiger;
        letter-spacing: 1.5px;
        margin-bottom: 23px;
    }
    .scroll--text-button:hover {
        background: $sickly_green;
    }
    .scroll--text-value {
        display: flex;
        font-size: 0.875rem;
        font-family: Frutiger;
        color: $brown_grey_three;
        font-weight: 300;
        align-items: center;
        letter-spacing: 0.9px;
        margin-bottom: 20px;
        span {
            font-size: 1.0625rem;
            font-weight: 400;
            color: $greyish_brown;
        }
        .scroll--value-original {
            padding-left: 40px;
            padding-right: 30px;
        }
        .scroll--value-separator {
            width: 1px;
            height: 56px;
            background: $very_light_pink_three;
            opacity: 0.7;
        }
        .scroll--average-timeframe {
            padding-left: 30px;
            padding-right: 30px;
            text-align: center;
        }
        .scroll--value-discount {
            padding-left: 30px;
            .scroll--value-itens {
                display: flex;
                .scroll--value-img {
                    content: url('../../../../../images/info.svg');
                    padding-left: 3px;
                    cursor: pointer;
                }
            }
        }
    }
    .scroll--text-terms {
        font-family: Frutiger;
        font-size: 0.8125rem;
        color: $greyish_brown;
        opacity: 0.7;
    }
}
