@import '../../media-query';
@import '../../styles/constants/colors.scss';

.container__program-selection--loading {
    min-height: 100vh;
    .gfLNMF {
        .iepmoa {
            background-color: $organization_primary_focus;
        }
    }
}

.container__program-selection--error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    .program-selection__error-message {
        font-family: 'Catamaran', sans-serif;
        font-size: 1.25rem;
        color: $scorpion;
        text-align: center;
        line-height: 20px;
        margin-bottom: 30px;
    }
    .button__style--back .MuiButton-contained:hover,
    .button__style--back .MuiButton-contained {
        max-width: 220px;
    }
}

.container__program-selection--empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    font-family: 'Catamaran', sans-serif;
    font-size: 1.25rem;
    color: $scorpion;
    text-align: center;
    line-height: 20px;
    white-space: pre-wrap;
}

.container__program-selection {
    padding-top: 70px;
    background-color: #fafafa;
    height: 100vh;

    .container {
        height: 92%;
        overflow-y: auto;
        scrollbar-width: none;
    }

    .container::-webkit-scrollbar{
        display: none;
    }

    .program-selection--title {
        font-family: 'Frutiger';
        font-size: 1.5rem;
        color: $greyish_brown;
        font-weight: 500;
    }
}
