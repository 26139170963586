@import '../../media-query';
@import '../../styles/constants/colors.scss';
@import '../../styles/constants/fonts.scss';

.body__forgot-password {
    margin-top: 60px;
    flex-direction: column;
    align-items: center;
    font-family: 'Frutiger';
    @include responsive(s) {
        margin-top: 10px;
    }

    .body__password--buttons {
        display: flex;
        flex-wrap: initial;
        margin-bottom: 30px;
        @include responsive(s) {
            padding-top: 10px;
            margin-bottom: 5px;
            flex-wrap: nowrap;
        }
        @include responsive(ss) {
            padding-top: 10px;
            margin-bottom: -14px;
            flex-wrap: nowrap;
        }
    }

    &--title {
        margin: auto;
        font-size: 1.375rem;
        text-align: center;
        margin-bottom: 48px;
        line-height: 1.3;
        @include responsive(s) {
            margin-bottom: 15px;
        }

        span {
            font-weight: 500;
        }
    }

    .form__style,
    .container__button--actions {
        @include responsive(s) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .forgot-password--button:hover {
        background: $organization_primary_focus;
    }

    .container__button--actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 50px;
        @include responsive(s) {
            margin-top: 10px;
        }
    }
}
