@import '../../../../../media-query';
@import '../../../../../styles/constants/colors.scss';
.container__simulate-invoices {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    border: solid 1px $light-periwinkle;
    background: $white_two;
    box-shadow: 0 -2px 4px 0 $light_periwinkle;
    z-index: 110;
    height: 80px;
    align-items: center;
    .container_simulate-invoices--inactivated {
        display: flex;
        justify-content: center;
        color: $scorpion;
        text-align: center;
        font-family: Frutiger;
        font-size: 1rem;
        width: 100%;
    }
    .container__simulate-invoices--buttons {
        display: flex;
        align-items: flex-end;
        .container__simulate-invoices--buttons-cancel {
            margin-right: 40px;
            width: 165px;
            height: 45px;
            border-radius: 8px;
            border: solid 1px $very_light_pink_two;
            background: $white_two;
            font-family: Frutiger;
            font-size: 0.75rem;
            font-weight: 500;
            color: $brown_grey_three;
        }
        .container__simulate-invoices--buttons-cancel:hover {
            border-color: $brown_grey;
            color: $brown_grey;
        }
        .container__simulate-invoices--buttons-simulate {
            width: 273px;
            height: 45px;
            border-radius: 8px;
            border: none;
            background: $organization_primary;
            font-family: Frutiger;
            font-size: 0.75rem;
            font-weight: 500;
            color: white;
        }
        .container__simulate-invoices--buttons-simulate:hover {
            background: $organization_primary_focus;
        }
    }
}