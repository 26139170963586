@import '../../media-query.scss';
@import '../../styles/constants/colors.scss';
@import '../../styles/constants/fonts.scss';

.error-fallback {
    &__container {
        display: flex;
        flex-direction: column;
        flex: 1;

        width: 100vw;
        height: 100vh;
    }

    &__message {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
    }

    &__message-title {
        font-family: 'Catamaran', sans-serif;
        font-size: 2.5rem;
        color: $scorpion;
        text-align: center;
        margin-bottom: 2vh;
    }

    &__message-sub-title {
        font-family: 'Catamaran', sans-serif;
        font-size: 1.6rem;
        color: $scorpion;
        text-align: center;
        margin-bottom: 1vh;
        max-width: 60vw;
    }

    &__message-tips {
        font-family: 'Catamaran', sans-serif;
        font-size: 1.3rem;
        color: $bluey_grey;
        text-align: center;
        margin-bottom: 0.5vh;
        max-width: 60vw;
    }

    &__message-contact {
        font-family: 'Catamaran', sans-serif;
        font-size: 1.3rem;
        color: $bluey_grey;
        text-align: center;
        max-width: 60vw;
    }

    &__error-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-top-style: solid;
        border-top-width: 1px;
        border-top-color: $cloudy_blue;
        padding-top: 1.2vh;
        padding-bottom: 6vh;

        &--hidden {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
        }

        &--visible {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    &__pointer{
        cursor: pointer;
    }

    &__details-title {
        font-family: 'Catamaran', sans-serif;
        font-size: 0.9rem;
        color: $bluey_grey;
        text-transform: uppercase;
    }

    &__details-table {
        max-width: 85vw;
    }

    &__details-row {
        margin-bottom: 5px;
    }

    &__details-property {
        font-family: 'Catamaran', sans-serif;
        font-size: 1rem;
        color: $bluey_grey;
        text-align: right;
        padding-right: 5px;
    }

    &__details-info {
        font-family: 'Catamaran', sans-serif;
        font-size: 0.8rem;
        color: $scorpion;
        text-align: left;
    }
}
