@import '../../../../media-query';
@import '../../../../styles/constants/colors.scss';
@import '../../../../styles/constants/fonts.scss';

.body__terms-of-use {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Frutiger';
    width: 100%;
    @include responsive(s) {
        margin-top: 20px;
    }

    .MuiCheckbox-colorSecondary.Mui-checked {
        color: $organization_primary !important;
    }

    &--buttons {
        display: flex;
        margin-bottom: 30px;
        @include responsive(ss) {
            flex-wrap: wrap;
        }
    }

    &--button-previous {
        display: flex;
        justify-content: flex-end;
        @include responsive(ss) {
            justify-content: center;
            padding-bottom: 20px;
        }
    }

    &--button-next {
        display: flex;
        justify-content: flex-start;
        @include responsive(ss) {
            justify-content: center;
        }
    }

    ul {
        @include responsive(s) {
            border: none;
            overflow: unset;
            max-height: none;
        }
    }

    li {
        @include responsive(s) {
            padding: 0px;
        }
    }

    .body__full-terms {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border: solid 1px $very_light_pink_two;
        border-top: none;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        margin-bottom: 30px;
        @include responsive(s) {
            flex-direction: column;
            border-radius: 4px;
            margin-top: 6px;
            margin-bottom: 40px;
        }

        a {
            font-size: 0.875rem;
            color: $cool_blue_two;
            font-weight: 300;
        }

        span {
            font-size: 0.875rem;
            font-weight: 300;
            @include responsive(sm) {
                max-height: 20px;
            }
            @include responsive(s) {
                max-height: none;
            }
        }

        &--text {
            &:nth-child(1) {
                padding-left: 25px;
                @include responsive(s) {
                    border-top: solid 1px $very_light_pink_two;
                    border-radius: 4px;
                    padding: 20px;
                    text-align: center;
                }
            }
            &:nth-child(2) {
                padding-right: 25px;
                text-align: right;
                @include responsive(s) {
                    border-top: solid 1px $very_light_pink_two;
                    padding: 10px;
                    text-align: center;
                }
            }
        }
    }

    &--title {
        margin: auto;
        font-size: 1.125rem;
        text-align: center;
        margin-bottom: 25px;
        line-height: 1.3;
        @include responsive(s) {
            font-size: 1.25rem;
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: 45px;
        }

        span {
            font-weight: 500;
        }
    }

    .body__terms-of-use--buttons {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 60px;
        @include responsive(s) {
            margin-bottom: 12px;
        }
    }

    .makeStyles-listSection-316 {
        padding: 40px 0px 0px 10px;
    }

    .body__terms-of-use--button-previous {
        .button--arrow {
            @include responsive(s) {
                transform: rotate(360deg);
                padding-left: 0;
                padding-bottom: 5px;
            }
        }
    }
}
