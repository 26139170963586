@import '../../../../media-query';
@import '../../../../styles/constants/colors.scss';

.body__reset-password-only {
    margin-top: 67px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Frutiger';
    width: 100%;
    @include responsive(s) {
        margin-top: 10px;
    }

    &--components {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        width: 100%;
        @include responsive(s) {
            margin-top: 20px;
            justify-content: center;
        }
    }

    &--buttons {
        display: flex;
        margin-bottom: 30px;
        margin: auto;
        @include responsive(ss) {
            flex-wrap: wrap;
        }
        @include responsive(s) {
            margin-bottom: 70px;
            margin-top: 20px;
        }
    }

    &--button-previous {
        display: flex;
        justify-content: flex-end;
        @include responsive(ss) {
            justify-content: center;
            padding-bottom: 20px;
        }
    }

    &--button-next {
        display: flex;
        justify-content: flex-start;
        @include responsive(ss) {
            justify-content: center;
        }
    }

    &--title {
        margin: auto;
        font-size: 1.375rem;
        text-align: center;
        margin-bottom: 48px;
        line-height: 1.3;
        @include responsive(s) {
            margin-bottom: 16px;
        }

        span {
            font-weight: 500;
        }
    }

    .body__reset-password-only--buttons {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
    }

    .password__input {
        margin-bottom: 50px;
        @include responsive(s) {
            margin-bottom: 22px;
        }
    }

    .body__password-strength {
        font-size: 0.625rem;
        font-weight: 400;
        margin-bottom: 7px;
        @include responsive(s) {
            font-size: 0.6875rem;
            text-align: center;
        }
    }

    .MuiCard-root {
        width: 157px;
        padding: 10px 10px 10px 10px;
        background: #f9f9f9;
        box-shadow: none;
        @include responsive(s) {
            width: 210px;
            padding: 1px 13px 1px 13px;
        }
    }

    .card__identify--text {
        padding: 0px;
    }

    .MuiPaper-root {
        background: #f9f9f9 !important;
    }

    .body__reset-password-only--buttons {
        .body__reset-password-only--button-next {
            .button--arrow {
                @include responsive(s) {
                    transform: rotate(360deg);
                    padding-left: 0;
                    padding-top: 5px;
                }
            }
        }
    }
}
