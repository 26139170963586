@import '../../media-query';
@import '../../styles/constants/colors.scss';

.text__field.MuiFormControl-root {
    border-style: solid;
    border-color: $ice_blue;

    border-radius: 6px;
    border-width: 1px;
    background-color: $white_three;

    .MuiFilledInput-root {
        background-color: $white_three;
        color: $greyish_brown;
        border-radius: 5px;
    }

    &.text__field--focused {
        .MuiFilledInput-root {
            color: $black;
        }
    }

    &.text__field--focused,
    &.text__has-value {
        border-color: $brown_grey_two;
        background-color: $white_two;
        transition: 1s;

        .MuiFilledInput-root {
            transition: 1s;
            background-color: $white_two;
        }

        .MuiFormHelperText-root.MuiFormHelperText-contained {
            transition: 1s;
            border-color: $brown_grey_two;
        }
    }

    &.text__field--valid {
        transition: 1s;
        border-top-color: $gross_green;
    }

    &.text__field--has-error {
        transition: 1s;
        border-top-color: $punch;
    }

    .MuiFilledInput-underline:after {
        border-bottom: transparent;
        top: 0;
        bottom: unset;
        margin: auto;
        width: 99%;
    }

    .MuiFilledInput-underline:before {
        display: none;
    }

    .MuiFormLabel-root,
    .MuiFormLabel-root.Mui-focused {
        color: $brown_grey;
        font-family: Frutiger;
    }

    .text__field--valid-adornment {
        .MuiSvgIcon-root {
            color: $gross_green;
        }
    }

    .MuiFormHelperText-root.MuiFormHelperText-contained {
        color: $greyish_brown;
        border-style: solid;
        border-width: 0px;
        border-top-width: 1px;
        border-color: $ice_blue;
        font-size: 0.875rem;
        padding: 12px 25px 12px 10px;
        font-family: Frutiger;
        letter-spacing: 0.5px;
        line-height: 1.3;
        margin: 0px;
        background-color: $white_two;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        &.Mui-error {
            transition: 1s;
            color: $punch;
        }
    }
}
