@import '../../../../media-query';
@import '../../../../styles/constants/colors.scss';

.content__modal-reset-password-success--itens {
    display: flex;
    flex-direction: column;
    align-items: center;

    .content__modal-reset-password-success--image {
        content: url('../../../../images/positivo.svg');
        margin: auto;
    }
    .content__modal-reset-password-success--title {
        font-family: Frutiger;
        font-size: 1.3rem;
        font-weight: 400;
        color: $greyish_brown;
        margin-top: 8vh;
        margin-bottom: 3vh;
    }

    .content__modal-reset-password-success--message {
        font-family: Frutiger;
        font-size: 1.1rem;
        font-weight: 300;
        color: $greyish_brown;
        margin-bottom: 7vh;
    }

    .button__style--action .MuiButton-contained {
        @include responsive(s) {
            max-width: 80vw;
        }
    }

    .content__modal-reset-password-success--button {
        background: $organization_primary;
        width: 260px;
        height: 45px;
        border: none;
        border-radius: 8px;
        font-family: 'Frutiger';
        font-weight: 500;
        font-size: 0.75rem;
        letter-spacing: 1.5px;
        color: white;
        padding: 16px;
    }

    .content__modal-reset-password-success--button:hover {
        background: $organization_primary_focus;
    }
}
