@import '../../../../media-query';
@import '../../../../styles/constants/colors.scss';

.dashboard__program-selection--loading {
    min-height: 279px;
    .gfLNMF {
        .iepmoa {
            background-color: $organization_primary_focus;
        }
    }
}

.dashboard__program-selection--error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 279px;
    background-color: $white_two;
    border: solid $mercury 1px;
    border-radius: 8px;
    .program-selection__error-message {
        font-family: 'Catamaran', sans-serif;
        font-size: 1.25rem;
        color: $scorpion;
        text-align: center;
        line-height: 20px;
        margin-bottom: 30px;
    }
    .button__style--back .MuiButton-contained:hover,
    .button__style--back .MuiButton-contained {
        max-width: 220px;
    }
}

.dashboard__program-selection--empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 279px;
    font-family: 'Catamaran', sans-serif;
    font-size: 1.25rem;
    color: $scorpion;
    text-align: center;
    line-height: 20px;
    white-space: pre-wrap;
    background-color: $white_two;
    border: solid $mercury 1px;
    border-radius: 8px;
}
