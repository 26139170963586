@import '../../../../styles/constants/colors.scss';
@import '../../../../styles/constants/fonts.scss';
@import '../../../../media-query';

.login--logo {
    content: url('../../../../images/custom_organization/white_logo.svg');
    position: absolute;
    top: 40px;
    left: 50px;
    max-width: 150px;
    z-index: 1;
}

.login--form-logo {
    display: flex;
    justify-content: center;
    padding: 20px 20px 50px;
    width: 100%;
    &-image {
        content: url('../../../../images/custom_organization/logo.svg');
        width: 150px;
    }
}

.login--title {
    color: $greyish_brown;
    font-size: 0.875rem;
    font-family: 'Frutiger';
    font-weight: 500;

    p {
        margin: 0px;
    }
}

.login--link {
    text-decoration: underline;
    font-size: 0.8125rem;
    font-family: 'Frutiger';
    text-align: right;

    a {
        color: #707070;
    }
}

.login--container {
    .login--buttons {
        display: flex;
        justify-content: space-between;

        .button__style--action {
            .MuiButton-contained:hover,
            .MuiButton-contained {
                width: 165px;
                height: 55px;
                font-family: 'Frutiger';
                font-weight: 500;
                font-size: 0.875rem;
            }
        }
    }
}

.login--column-adjust {
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login--footer-logo {
    display: none;
    justify-content: center;
    width: 100%;
    padding: 20px;
    &-image {
        content: url('../../../../images/custom_organization/footer_logo.svg');
    }
}