@import '../../../media-query';
@import '../../../styles/constants/colors.scss';
.container__invoices {
    padding-top: 115px;
    background-color: #fafafa;
    padding-bottom: 60px;
    min-height: 100vh;
    &--link {
        font-family: 'Frutiger';
        font-size: 0.98rem;
        color: $cool_blue_two;
        text-decoration: none;
        a {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 36px;
            color: $cool_blue_two;
            text-transform: uppercase;
        }
        .link--arrow {
            width: 22px;
        }
    }
    &--title {
        font-family: Frutiger;
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 20px;
    }
    &--subtitle {
        font-family: Frutiger;
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 5px;
        margin-top: 25px;
    }
    &--cards {
        .MuiPaper-elevation1 {
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
            border-radius: 6px;
        }
    }
}