@import '../../../../media-query';
@import '../../../../styles/constants/colors.scss';
@import '../../../../styles/constants/fonts.scss';

.body__identification {
    .body__password--buttons {
        .body__password--button-cancel {
            .button--arrow {
                @include responsive(s) {
                    transform: rotate(360deg);
                    padding-right: 0;
                    padding-bottom: 5px;
                }
            }
        }
    }
}
