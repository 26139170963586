@import '../../../../media-query';
@import '../../../../styles/constants/colors.scss';
@import '../../../../styles/constants/fonts.scss';

.body__invite-sucess--itens {
    font-family: 'Frutiger';
    text-align: center;
    height: 100%;

    .body__invite-sucess--image {
        content: url('./../../../../images/positivo.svg');
        margin: auto;
    }

    .body__invite-sucess--title-start {
        font-size: 1.5rem;
        font-weight: 300;
        color: $greyish_brown;

        span {
            font-weight: 400;
        }
    }

    .body__invite-sucess--title-end {
        font-weight: 300;
        font-size: 1.125rem;
    }

    .button--start-use {
        background: $organization_primary;
        width: 220px;
        height: 45px;
        border: none;
        border-radius: 8px;
        font-family: 'Frutiger';
        font-weight: 500;
        font-size: 0.75rem;
        letter-spacing: 1.5px;
        color: white;
    }

    .button--start-use:hover {
        background: $organization_primary_focus;
    }

    .body__invite-sucess--button {
        background: $organization_primary;
        width: 260px;
        height: 45px;
        border: none;
        border-radius: 8px;
        font-family: 'Frutiger';
        font-weight: 500;
        font-size: 0.75rem;
        letter-spacing: 1.5px;
        color: white;
        padding: 16px;
    }

    .body__invite-sucess--button:hover {
        background: $organization_primary_focus;
    }
}
