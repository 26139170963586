@import './styles/constants/colors.scss';
@import './styles/constants/fonts.scss';
@import '../src/media-query';
@import './styles/custom_organization/styles.scss';

/**************************************/
/* APP GENERAL */
/**************************************/

.Toaster__message-wrapper {
    height: 100px;
    padding: 3px;
    border-radius: 16px !important;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2) !important;
    background-image: linear-gradient(52deg, #f84073 21%, #f67d40 62%, #fecf44 100%) !important;

    .Toaster__alert_text {
        font-family: Raleway !important;
        font-size: 14px !important;
        font-weight: normal !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: 1.33 !important;
        letter-spacing: 0.45px !important;
        text-align: center !important;
        color: #ffffff !important;
    }
}

.Toaster__alert_close {
    color: #ffffff !important;
    font-family: Raleway !important;
    font-size: 14px !important;
}

.Toaster__alert {
    background-color: transparent !important;
    box-shadow: none !important;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

body {
    font-family: 'Raleway';
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    // min-height: 100vh;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.justity-content-center {
    justify-content: center;
}
.align-content-center {
    align-content: center;
}

/**************************************/
/* DASHBOARD */
/**************************************/

.step-container {
    margin-top: -28px;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-content: center;
}

.space-step-blank {
    width: 70px;
    height: 1px;
}

.space-step-on {
    width: 90px;
    height: 1px;
    border: solid 1px #1a3c47;
}

.space-step-off {
    width: 90px;
    height: 1px;
    border: solid 1px #b9b9b9;
}

.text-step {
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.46px;
    color: #1a3c47;
}

.head-step {
    margin: auto;
    position: relative;
    display: flex;
    align-items: center;
    width: 140px;
}

.head-step-text {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 7px;
    position: relative;
    display: flex;
    align-items: center;
    width: 152px;

    .disabled {
        font-size: 11px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.46px;
        color: #b9b9b9;
    }
}

.dash-example {
    width: 100vw;
    // content: url('./images/dash.png');
}

.progress-step {
    width: 25px;
    height: 25px;
    // content: url('./images/step-progress.svg');
}

.progress-finish {
    width: 25px;
    height: 25px;
    // content: url('./images/progress-finish.svg');
}

.progress-none {
    width: 13px;
    height: 13px;
    // content: url('./images/progress-none.svg');
}

.next-svg {
    width: 17px;
    height: 17px;
    margin-top: auto;
    margin-left: 10px;
    // content: url('./images/next-white.svg');
}

.back-svg {
    width: 17px;
    height: 17px;
    margin-top: auto;
    margin-right: 10px;
    // content: url('./images/back-white.svg');
}

.send-svg {
    width: 40px;
    height: 40px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 20px;
    // content: url('./images/send.svg');
}

/**************************************/
/* FREE REGISTER */
/**************************************/

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #5ab0ae !important;
}

.terms {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.27px;
    color: #707070;
}

.terms-link {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.27px;
    color: #ef7d00;
}
.message-box {
    display: flex;
    width: 488px;
    height: 64px;
    border-radius: 4px;
    background-color: #f5f5f5;

    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.27px;
    color: #4a4a4a;
    vertical-align: middle;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 12px;
    margin-top: 10px;
}

.success-back {
    margin-top: 30px;
    position: relative;
    width: 98px;
    height: 98px;
    border-radius: 50%;
    background-color: #fafafa;
}

.success-img {
    margin: 13px;
    position: absolute;
    align-content: center;
    width: 70px;
    height: 70px;
    // content: url('./images/sucesso.svg');
}

.label-radio {
    margin-top: 30px;
    margin-bottom: -10px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.67px;
    color: #1a3c47;
}

.radio-options {
    display: flex;
}

.main-text {
    margin-top: 45px;
    margin-bottom: 25px;
    font-size: 40px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1a3c47;
}

.sub-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
}

.register-container {
    align-items: center;
    align-content: center;
    text-align: center;
    margin: auto;
    width: 50%;
}

.blank-header {
    height: 80px;
    width: 100vw;
    border: solid 1px #e1e2e6;
    background-color: #ffffff;
}

.header-logo {
    cursor: pointer;
    position: relative;
    margin-top: 20px;
    margin-left: 15%;
    margin-bottom: 20px;
    width: 35px;
    height: 45px;
    // content: url('./images/logo@3x.png');
}

.header-logo-name {
    cursor: pointer;
    position: relative;
    margin-top: 15px;
    margin-left: 136%;
    margin-bottom: 15px;
    // width: 35px;
    // height: 45px;
    // content: url('./images/wemeds-logo-semihor.svg');
}

/**************************************/
/* LOGIN */
/**************************************/

.page-container {
    // height: 100vh;
    // overflow: auto;
}

.full-height {
    height: 100%;
}

.login-container {
    height: 60vh;
    width: 317px;
    margin-top: 30vh;
    margin-left: 86px;
}

.right {
    float: right;
    margin-left: auto;
}

.left {
    float: left;
    margin-right: auto;
}

.container-banner {
    margin: auto;
    width: 410px;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.title-banner {
    font-size: 28px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    color: #ffffff;
}

.description-banner {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #ffffff;
}

.link-orange {
    cursor: pointer;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.67px;
    color: #ef7d00 !important;
}

.link-sm {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #707070;
    cursor: pointer;
    text-decoration: underline !important;
}

.title-login {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.43;
    letter-spacing: normal;
    color: #4a4a4a;
}

.button-container {
    margin-top: 50px;
}

.button-primary {
    border-radius: 25.5px;
    height: 51px;
    border: none;
    width: 118px;

    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.67px;
    color: #ffffff;
    background-image: linear-gradient(to right, #f56f3d, #f1870b 57%, #f59e24);
}
.button-primary:hover {
    border-radius: 25.5px;
    height: 51px;
    border: none;
    width: 118px;

    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.67px;
    color: #ffffff;
    background-image: linear-gradient(to right, #f56f3d, #f1870b 57%, #f59e24);
    opacity: 0.7;
}

.button-second {
    width: 151px;
    height: 51px;
    border-radius: 28px;
    border: solid 1px #a4a4a4;
    background-color: #ffffff;

    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.67px;
    color: #a4a4a4;
}

.button-primary:hover {
    border-radius: 25.5px;
    background-color: #ff8400;
    height: 51px;
    border: #ff8400;
    min-width: 115px;

    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.67px;
    color: #ffffff;
}

.background-login {
    position: relative;
    background-size: auto 100%;
    // background-image: url('./images/login-background.png');
}

.logo {
    position: relative;
    margin-top: 20px;
    margin-left: 10px;
    margin-bottom: 5vh;
    width: 35px;
    height: 45px;
    // content: url('./images/logo@3x.png');
}

.display-mobile {
    margin-left: -22px;
    bottom: 0;
    margin-top: auto;
    position: relative;
    width: 60vh;
    // content: url('./images/mobile-image.png');
}

.link-google-play {
    position: relative;
    margin-right: 10px;
    width: 175px;
    height: 47px;
    object-fit: contain;
    // content: url('./images/download-google-play.png');
}

.link-apple-store {
    margin-left: 10px;
    position: relative;
    width: 160px;
    height: 47px;
    object-fit: contain;
    // content: url('./images/download-app-store.png');
}

.button-container-login {
    margin-top: 50px;
}
