@import '../../../../../media-query';
@import '../../../../../styles/constants/colors.scss';

.provider-inactive-message {
    margin: 0px 20px;
    overflow-y: auto;
    min-height: 5vh;
    max-height: 16vh;
    border: solid 1px $apricot;
    border-radius: 3px;
    padding: 10px 20px;
    position: relative;
    z-index: 0;
    background-color: $white_two;

    &__text {
        font-family: 'Frutiger';
        font-size: 0.875rem;
        font-weight: 300;
        text-align: left;
    }

    p {
        margin-right: 15px;
    }
}
