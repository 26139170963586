/*

 Custom colors for Planium organization

            **WARNING**
 Make sure to make changes in custom folder
 Changes made in source will be ignored

*/

$organization_primary: #181F18;
$organization_primary_focus: #2CCCD9;
$organization_primary_disabled: #2CCCD9;

$organization_program_default: #181F18;

$organization_secondary: #BEDAF7;
$organization_secondary_focus: #BEDAF7;
$organization_secondary_transparent: #2CCCD9;
$organization_secundary_border: #ffffff;

$organization_secundary_link: #ffffff;
$organization_secundary_link_hover: #2CCCD9;

$organization_secundary_button: #181F18;
$organization_secundary_button_focus: #181F18;
