@import '../../../../media-query';
@import '../../../../styles/constants/colors.scss';
@import '../../../../styles/constants/fonts.scss';
@use "@material/animation";

.responsive-step {
    margin-left: -15px;
    margin-right: -15px;

    .responsive-step--text {
        border-bottom: solid 1px $very_light_pink_two;
        padding: 18px 20px 18px 20px;
        @include responsive (s) {
            padding: 15px 20px 15px 20px;
        }
    }
}
