@import '../../media-query.scss';
@import '../../styles/constants/colors.scss';
.modal-company__itens-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    padding: 10px;
    span {
        font-family: Frutiger;
        font-size: 1.5rem;
        font-weight: 500;
        color: $greyish_brown;
    }
}

.modal-company__itens-img {
    position: relative;
    cursor: pointer;
    float: right;
    right: 20px;
    top: 20px;
}